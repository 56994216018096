import React from 'react';
import "../styles/loader/main.css"

const MobileScreen = () => {

  return (
    <div className='MobileContainer'>
        <div className='mobile-home'>
            <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 317.63 216.97" className='mobile-screen' >
                <defs>
                    <style>{`.cls-1,.cls-2,.cls-3,.cls-4,.cls-5,.cls-6{stroke:#252227;}.cls-1`}, {`.cls-2,.cls-4,.cls-5{stroke-miterlimit:10;}.cls-1`}, {`.cls-3,.cls-4,.cls-6{fill:none;stroke-linecap:round;}.cls-1`}, {`.cls-6{stroke-width:8px;}.cls-2{stroke-width:1.22px;}.cls-2`} {`.cls-6{stroke-linejoin:round;}.cls-4{stroke-width:6px;}.cls-7{stroke-width:0px;}.cls-5{fill:#fff;stroke-width:3px;}`}
                    </style>
                </defs>
                <g id="Layer_4">
                    <path className="cls-1" d="m162,34s18-30,32-30,16,44,16,44"/>
                    <path className="cls-1" d="m100,34s-18-30-32-30c-14,0-16,44-16,44"/>
                    <path className="cls-1" d="m54,64s-18,21-11,53c6.64,30.34,35,23.82,35,23.82"/>
                    <path className="cls-6" d="m103.67,113s-4.52,8.75-3.61,18c.9,9.25,4.64,19.22,14.41,20,12.53,1,20.48-19,20.48-19h.05s8.01,20,20.48,19c9.77-.78,13.51-10.75,14.41-20,.9-9.25-3.61-18-3.61-18"/>
                    <line className="cls-1" x1="159.93" y1="69.89" x2="181" y2="90.96"/>
                    <line className="cls-1" x1="180.76" y1="69.53" x2="159.69" y2="90.61"/>
                    <line className="cls-1" x1="4" y1="105" x2="26.54" y2="105"/>
                    <line className="cls-1" x1="4.09" y1="117.09" x2="26.63" y2="117.09"/>
                    <line className="cls-1" x1="213.26" y1="102.05" x2="235.8" y2="102.05"/>
                    <line className="cls-1" x1="213.35" y1="114.14" x2="235.89" y2="114.14"/>
                    <ellipse className="cls-2" cx="100.52" cy="81.44" rx="7.6" ry="10.78"/>
                    <polygon className="cls-6" points="280.24 212.97 170 212.97 203.39 136.7 313.63 136.7 280.24 212.97"/>
                    <polyline className="cls-6" points="170 212.97 114.15 166.27 190.45 166.27"/>
                    <polyline className="cls-3" points="176.88 192.96 162.94 182.2 183.44 182.28"/>
                    <circle className="cls-5" cx="241.82" cy="179.12" r="17.53"/>
                    <circle className="cls-5" cx="241.82" cy="179.12" r="17.53"/>
                    <path className="cls-7" d="m250.02,164.05c-.11,6.27-4,11.45-8.02,15.91-3.69,4.09-7.9,7.98-13.23,9.74-1.52.5-.87,2.92.66,2.41,5.89-1.95,10.54-6.14,14.61-10.69,4.38-4.9,8.36-10.56,8.48-17.38.03-1.61-2.47-1.61-2.5,0h0Z"/>
                    <path className="cls-7" d="m254.67,168.46c-1.56,5.3-4.76,10.08-8.14,14.4s-7.41,8.14-12.51,10.17c-1.48.59-.83,3.01.66,2.41,5.54-2.2,9.97-6.18,13.62-10.81s7.09-9.76,8.79-15.5c.46-1.55-1.96-2.21-2.41-.66h0Z"/>
                    <path className="cls-7" d="m244.93,162.45c-3.83,9.33-11.29,16.55-19.51,22.12-1.32.9-.08,3.06,1.26,2.16,8.82-5.97,16.56-13.63,20.66-23.61.61-1.49-1.81-2.14-2.41-.66h0Z"/>
                    <path className="cls-7" d="m233.48,165.99c1.5.21,2.59.71,3.84,1.57.15.1.29.21.44.31.18.13-.11-.09.07.05.07.06.14.11.21.17.29.24.58.49.86.75.56.52,1.09,1.07,1.61,1.62.45.48,1.32.49,1.77,0,.47-.51.48-1.26,0-1.77-2.2-2.34-4.82-4.65-8.13-5.12-.64-.09-1.37.16-1.54.87-.13.59.18,1.44.87,1.54h0Z"/>
                    <path className="cls-7" d="m228.7,169.76c1.58.64,3.3,1.47,4.83,2.42,1.45.9,2.93,2.05,3.92,3.35.41.54,1.08.82,1.71.45.53-.31.86-1.17.45-1.71-2.56-3.36-6.41-5.35-10.25-6.91-.63-.25-1.37.28-1.54.87-.2.72.24,1.28.87,1.54h0Z"/>
                    <path className="cls-7" d="m225.25,176.15c2.89.25,5.74,1.35,8.16,3.1.55.39,1.39.09,1.71-.45.37-.63.1-1.31-.45-1.71-2.76-1.99-6.04-3.14-9.42-3.44-.67-.06-1.25.62-1.25,1.25,0,.73.57,1.19,1.25,1.25h0Z"/>
                    <path className="cls-7" d="m244.65,187.94c.56.37,1.11.76,1.65,1.17.07.06.15.11.22.17.16.12-.14-.11.01.01.13.11.27.21.4.32.28.22.55.45.82.69,1.04.9,2.02,1.85,2.94,2.87.44.49,1.33.48,1.77,0,.48-.52.47-1.25,0-1.77-1.94-2.14-4.15-4.02-6.55-5.61-.55-.36-1.41-.12-1.71.45-.33.63-.13,1.32.45,1.71h0Z"/>
                    <path className="cls-7" d="m247.74,184.3c1.15,1.04,2.22,2.16,3.2,3.36.12.15.24.3.36.45.03.04.12.15.04.05.06.08.13.17.19.25.23.31.45.61.67.93.45.64.87,1.31,1.27,1.98.33.56,1.14.82,1.71.45s.8-1.11.45-1.71c-1.65-2.79-3.72-5.35-6.12-7.53-.48-.44-1.3-.51-1.77,0-.44.48-.52,1.3,0,1.77h0Z"/>
                    <path className="cls-7" d="m250.25,180.52c1.1,1.2,2.03,2.63,2.97,4.11.79,1.23,1.57,2.48,2.27,3.77.32.59,1.14.78,1.71.45s.77-1.12.45-1.71c-.73-1.33-1.54-2.63-2.36-3.91-.99-1.55-2.03-3.12-3.27-4.48-.46-.5-1.3-.46-1.77,0-.5.5-.46,1.27,0,1.77h0Z"/>
                    <line className="cls-4" x1="158.15" y1="58.94" x2="182.78" y2="51.12"/>
                    <line className="cls-4" x1="83.75" y1="55.73" x2="108.12" y2="62.33"/>
                </g>
            </svg>
            <p className='mobile-text'>Portfolio not ready for mobile device yet. please revisit in a PC</p>
        </div>
    </div>
  );
};

export default MobileScreen;
